<template>
  <form @submit="save()">
    <div class="form-group">
      <label for>Almacen Solicitante</label>
      <SelectStore v-model="store_id"></SelectStore>
      <app-small-form-errors :errors="errors.store_id"></app-small-form-errors>
    </div>
    <div class="form-group">
      <label for="">Tipo de Requerimiento</label>
      <select v-model="type_id" class="custom-select">
        <option :value="2">INTERNO</option>
        <option :value="1">EXTERNO</option>
      </select>
    </div>
    <div class="form-group" v-show="type_id == 2">
      <label for>Proveedor</label>
      <SelectStore v-model="target_store_id"></SelectStore>
      <app-small-form-errors
        :errors="errors.target_store_id"
      ></app-small-form-errors>
    </div>
    <!-- <div class="form-group">
      <label for="">Proveedor</label>
      <SelectSupplier v-model="supplier_id"></SelectSupplier>
    </div> -->
    <app-button-submit :disabled="errors" @click="save()"></app-button-submit>
  </form>
</template>

<script>
import SelectStore from "../stores/Select";
import validate from "validate.js";
import { LogisticService } from "../service";
// import SelectSupplier from "../suppliers/Select";

const formRules = function(data) {
  return {
    store_id: {
      presence: { message: "Requerido" }
    },
    target_store_id: {
      // presence: { message: "Requerido" },
      exclusion: {
        within: [data.store_id],
        message: "No puedes usar ese almacen"
      }
    }
  };
};

export default {
  components: {
    SelectStore
    // SelectSupplier
  },
  data: () => ({
    store_id: undefined,
    target_store_id: undefined,
    type_id: null,
    supplier_id: null
  }),
  computed: {
    errors() {
      let err = validate(this.$data, formRules(this.$data), {
        fullMessages: false
      });
      return err ? err : false;
    }
  },
  methods: {
    save() {
      LogisticService.saveRequirement(this.$data).then((res) => {
        this.$emit("submitted", res);
      });
    }
  }
};
</script>

<style></style>
